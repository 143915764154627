import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography, Box } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import { useTranslation } from 'react-i18next';
import Image from "next/image";


const useStyles = makeStyles(theme => ({
  root: {
    height: "650px"
  },
  root_mobile: {
    height: "850px",
    width: "100%",
    paddingBottom: "50px"
  },
  top: {
    width: "100%",
    backgroundColor: "#f3f4f5",
    height: "50%",
  },
  bottom: {
    width: "100%",
    backgroundColor: theme.palette.primary,
    height: "50%",
    display: "flex",
    justifyContent: "center"
  },
  banner: {
    height: "400px",
    width: "1440px",
    position: "relative",
    bottom: "200px",
    display: "flex",
    // backgroundImage: "url(images/playstore-banner-web.png)",
    backgroundImage: "url(images/playstore-banner-web_blank.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%"
  },
  banner_mobile: {
    display: "flex",
    height: "100%",
    // backgroundImage: "url(images/playstore-banner-mobile.png)",
    backgroundImage: "url(images/playstore-banner-mobile_blank.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%"
  },
  bannerText: {
    width: "450px",
    marginLeft: "120px",
    paddingTop: '50px'
  },
  bannerText_mobile: {
    //width: "320px",\
    width: '100%',
    paddingTop: "50px",
  },
  subscribe: {
    backgroundColor: "#f18d0f",
    width: "20%",
    border: "transparent",
    borderRadius: "5px",
    height: "37px",
    color: "white",
    "&:hover": {
      backgroundColor: "grey"
    },
    marginBottom: "20px",
    marginLeft: "10px",
  },
  subscribe_mobile: {
    backgroundColor: "#f18d0f",
    width: "40%",
    border: "transparent",
    borderRadius: "5px",
    height: "25px",
    color: "white",
    // "&:hover": {
    //   backgroundColor: "grey"
    // },
    marginTop: "1px",
    opacity: "0.8"
  },
  imgButton: {
    padding: 0,
    marginTop: "10px",
    backgroundColor: "transparent",
    boxShadow: "0 0px 0px 0px",
    maxWidth: 190,
    width: "100%",
    height: "100%"
  },
  imgButtonWeb: {
    padding: 0,
    backgroundColor: "#ebebeb",
    color: "#ebebeb",
    boxShadow: "0 0px 0px 0px",
    maxWidth: 190,
    borderRadius: "5px",
    width: "100%",
    height: "100%"
  },
  imgButtonWebIOS: {
    padding: 0,
    marginLeft: "10px",
    backgroundColor: "#ebebeb",
    color: "#ebebeb",
    boxShadow: "0 0px 0px 0px",
    maxWidth: 180,
    width: "100%",
    height: "100%"
  },
  imgMockup1Web: {
    maxWidth: 175,
    marginLeft: 75,
    marginTop: 125,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  imgMockup2Web: {
    maxWidth: 175,
    marginTop: 25,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  imgMockup1Mob: {
    maxWidth: 175,
    marginTop: 150,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  imgMockup2Mob: {
    maxWidth: 175,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  }
}));

function MobileBanner(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  React.useEffect(() => {
    console.log(new Date(0))
    //document.cookie = "authtoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }, []);

  return (
    <>
      {props.version == "mobile" ? (
        <div className={classes.root}>
          <div className={classes.top}></div>
          <div className={classes.bottom}>
            <Paper elevation={4} className={classes.banner}>
              <div className={classes.bannerText}>
                <Card style={{alignSelf: 'center', backgroundColor: 'transparent', border: "none", boxShadow: "none"}}>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "auto",
                      minHeight: "100px",
                    }}
                  >
                    <Image
                      src="/images/logo1.png"
                      title={t("EVLoader Logo")}
                      alt={t("EVLoader Logo")}
                      layout="fill"
                      objectFit="contain"
                      quality="10"
                    />
                  </div>
                </Card>

                <Typography color="textSecondary" gutterBottom variant="h6" style={{ marginTop: '30px'}}>
                  {t("Κατέβασε την εφαρμογή στο κινητό σου και φόρτισε το ηλεκτρικό σου όχημα εύκολα και γρήγορα")}
                </Typography>

                <Box display="flex" justifyContent="flex-center" >
                  <Card className={classes.imgButtonWeb} style={{backgroundColor: 'transparent'}}>
                    <CardActionArea component="a" href="https://play.google.com/store/apps/details?id=com.parityplatform.evloader">
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          height: "auto",
                          minHeight: "50px",
                        }}
                      >
                        <Image
                          src="/images/google-play-badge-gr.png"
                          title={t("Κατέβασε την εφαρμογή στο Google Play")}
                          alt={t("Κατέβασε την εφαρμογή στο Google Play")}
                          layout="fill"
                          objectFit="contain"
                          quality="10"
                          style={{ backgroundColor: "transparent" }}
                        />
                      </div>
                    </CardActionArea>
                  </Card>

                  <Card className={classes.imgButtonWebIOS} style={{backgroundColor: 'transparent'}}>
                    <CardActionArea component="a" href="https://apple.co/3d2SFgp">
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          height: "auto",
                          minHeight: "50px",
                        }}
                      >
                        <Image
                          src="/images/app-store-gr.svg"
                          title={t("Κατέβασε την εφαρμογή στο App Store")}
                          alt={t("Κατέβασε την εφαρμογή στο App Store")}
                          layout="fill"
                          objectFit="contain"
                          quality="10"
                          style={{ backgroundColor: "transparent" }}
                        />
                      </div>
                    </CardActionArea>
                  </Card>
                </Box>
              </div>

              <div style={{display: 'flex', width: '60%', justifyContent: 'space-around'}}>
                {/* Mock up Render */}
                <Card className={classes.imgMockup1Web}>
                  <CardMedia
                    component="img"
                    alt="EV Loader mobile app - my wallet screen"
                    image="images/wallet_Galaxy s20 Mockup@1x.png"
                    title="EV Loader mobile app - my wallet screen"
                  />
                </Card>
                <Card className={classes.imgMockup2Web}>
                <CardMedia
                  component="img"
                  alt="EV Loader mobile app - stations map screen"
                  image="images/map_Galaxy_s20_Mockup.png"
                  title="EV Loader mobile app - stations map screen"
                />
              </Card>
              </div>
            </Paper>
          </div>
        </div>


      ) : (
        <div className={classes.root_mobile}>
          <Paper elevation={3} className={classes.banner_mobile}>
            <div className={classes.bannerText_mobile}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Card style={{width: "80%", alignSelf: 'center', backgroundColor: 'transparent', border: "none",boxShadow: "none"}}>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "auto",
                      minHeight: "100px",
                      borderRadius: "3px",
                      filter: `grayscale('100%')`,
                    }}
                  >
                    <Image
                      src="/images/logo1.png"
                      title={t("EVLoader Logo")}
                      alt={t("EVLoader Logo")}
                      layout="fill"
                      objectFit="contain"
                      quality="10"
                    />
                  </div>
                </Card>

                <Typography color="textSecondary" gutterBottom variant="h6" style={{textAlign: 'center', marginTop: '25px'}}>
                  {t("Κατέβασε την εφαρμογή στο κινητό σου και φόρτισε το ηλεκτρικό σου όχημα εύκολα και γρήγορα")}
                </Typography>

                <Box display="flex" alignItems="center" style={{flexDirection: 'column'}}>
                  <Card className={classes.imgButton}>
                    <CardActionArea component="a" href="https://play.google.com/store/apps/details?id=com.parityplatform.evloader">
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          height: "60px",
                          minHeight: "50px",
                        }}
                      >
                        <Image
                          src="/images/google-play-badge-gr.png"
                          title={t("Κατέβασε την εφαρμογή στο Google Play")}
                          alt={t("Κατέβασε την εφαρμογή στο Google Play")}
                          layout="fill"
                          objectFit="contain"
                          quality="10"
                          style={{ backgroundColor: "transparent" }}
                        />
                      </div>
                    </CardActionArea>
                  </Card>

                  <Card className={classes.imgButton}>
                    <CardActionArea component="a" href="https://apple.co/3d2SFgp">
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          height: "60px",
                          minHeight: "50px",
                        }}
                      >
                        <Image
                          src="/images/app-store-gr.svg"
                          title={t("Κατέβασε την εφαρμογή στο App Store")}
                          alt={t("Κατέβασε την εφαρμογή στο App Store")}
                          layout="fill"
                          objectFit="contain"
                          quality="10"
                          style={{ backgroundColor: "transparent" }}
                        />
                      </div>
                    </CardActionArea>
                  </Card>
                </Box>
              </div>

              <div style={{display: 'flex', height: '63%', width: "100%", justifyContent: "space-around", marginTop: 50}}>
                {/*Mockup Mobile */}
                <Card className={classes.imgMockup1Mob}>
                  <CardMedia
                    component="img"
                    alt="EV Loader mobile app - my wallet screen"
                    image="images/wallet_Galaxy s20 Mockup@1x.png"
                    title="EV Loader mobile app - my wallet screen"
                  />
                </Card>

                <Card className={classes.imgMockup2Mob}>
                  <CardMedia
                    component="img"
                    alt="EV Loader mobile app - stations map screen"
                    image="images/map_Galaxy_s20_Mockup.png"
                    title="EV Loader mobile app - stations map screen"
                  />
                </Card>
              </div>
            </div>
          </Paper>
        </div>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    my_stations: state.stations.station_owner_stations
  };
};

export default MobileBanner;
